// import React, { createContext, useState, useEffect, useContext } from 'react';
// import { SnackBar } from './SnackBar';

// const NotificationsContext = createContext();

// export const NotificationsProvider = ({ children }) => {
//   const [notificationMessage, setNotificationMessage] = useState(null);
//   const [snackbarVisible, setSnackbarVisible] = useState(false);
//   const userToken = localStorage.getItem("token");
//   const idDoc = localStorage.getItem("idDoc");
//   const BASE_URL = 'https://staging.api.data-lex.net';

//   const startEventStream = () => {
//     const eventSource = new EventSource(`${BASE_URL}/__transmit/events?uid=${userToken}`);

//     eventSource.onmessage = function (event) {
//       try {
//         console.log('Event reçu brut:', event);
//         const data = JSON.parse(event.data);
//         console.log('Données de l\'événement après parsing:', data);
//         setNotificationMessage('Transcription réussie');
//         setSnackbarVisible(true);
//       } catch (error) {
//         console.error('Erreur de parsing des données de l\'événement:', error, event.data);
//       }
//     };

//     eventSource.onerror = function () {
//       console.error('Échec de EventSource. Tentative de reconnexion dans 5 secondes...');
//       eventSource.close();
      
//       // Tentative de reconnexion après 5 secondes
//       setTimeout(() => {
//         startEventStream();
//       }, 5000);
//     };

//     return () => {
//       eventSource.close();
//     };
//   };


//   useEffect(() => {
//     const closeEventStream = startEventStream();
//     // Nettoyage lors du démontage
//     return () => {
//       closeEventStream();
//     };
//   }, [idDoc]);

//   const handleSnackbarClose = () => {
//     setSnackbarVisible(false);
//     setNotificationMessage(null);
//   };

//   return (
//     <NotificationsContext.Provider value={{}}>
//       {children}
//       {snackbarVisible && (
//         <SnackBar 
//           message={notificationMessage} 
//           timeToHide={3000} 
//           onClose={handleSnackbarClose} 
//         />
//       )}
//     </NotificationsContext.Provider>
//   );
// };

// export const useNotifications = () => useContext(NotificationsContext);
import React, { createContext, useState, useEffect, useContext } from 'react';
import { SnackBar } from './SnackBar';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const userToken = localStorage.getItem("token");
  const idDoc = localStorage.getItem("idDoc");
  const BASE_URL = 'https://staging.api.data-lex.net';

  const startEventStream = () => {
    const eventSource = new EventSource(`${BASE_URL}/__transmit/events?uid=${userToken}`);
    let simulatedNotificationTimeout;

    // Fonction pour déclencher la notification simulée après 5 minutes
    const startSimulatedNotificationTimer = () => {
      simulatedNotificationTimeout = setTimeout(() => {
        setNotificationMessage('Transcription réussie (simulée)');
        setSnackbarVisible(true);
      }, 5 * 60 * 1000); // 5 minutes
    };

    // Démarre le timer pour la notification simulée
    startSimulatedNotificationTimer();

    eventSource.onmessage = function (event) {
      try {
        console.log('Event reçu brut:', event);
        const data = JSON.parse(event.data);
        console.log('Données de l\'événement après parsing:', data);

        // Annule la notification simulée si une vraie notification est reçue
        clearTimeout(simulatedNotificationTimeout);

        // Affiche la vraie notification
        setNotificationMessage('Transcription réussie');
        setSnackbarVisible(true);

        // Redémarre le timer pour la notification simulée
        startSimulatedNotificationTimer();
      } catch (error) {
        console.error('Erreur de parsing des données de l\'événement:', error, event.data);
      }
    };

    eventSource.onerror = function () {
      console.error('Échec de EventSource. Tentative de reconnexion dans 5 secondes...');
      eventSource.close();

      // Tentative de reconnexion après 5 secondes
      setTimeout(() => {
        startEventStream();
      }, 5000);
    };

    // Nettoyage : ferme EventSource et annule le timeout
    return () => {
      eventSource.close();
      clearTimeout(simulatedNotificationTimeout);
    };
  };

  useEffect(() => {
    const closeEventStream = startEventStream();
    // Nettoyage lors du démontage
    return () => {
      closeEventStream();
    };
  }, [idDoc]);

  const handleSnackbarClose = () => {
    setSnackbarVisible(false);
    setNotificationMessage(null);
  };

  return (
    <NotificationsContext.Provider value={{}}>
      {children}
      {snackbarVisible && (
        <SnackBar 
          message={notificationMessage} 
          timeToHide={3000} 
          onClose={handleSnackbarClose} 
        />
      )}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);

