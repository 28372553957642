import { useEffect, useRef, useState } from "react"
import { MainHeaderFaqs } from "../../repeatableComponents/atomes/mainHeader/MainHeaderFaqs"
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal"
import question from "../../../assets/icons/question.png"
import shape from "../../../assets/icons/shape.png"
import right from "../../../assets/icons/right.png"
import eyes from "../../../assets/icons/eyes.png"
import remover from "../../../assets/icons/remover.png"
import circleNike from "../../../assets/icons/circleNike.png"
import "./mainFaqs.css";
import { fetchData } from "../../../assets/helpers/fetchData"
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm"
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar"

export const MainFaqs = () => {
    const [etat, setEtat] = useState(false)
    const [optionVisible, setOptionVisible] = useState(false)
    const [rotateIcon, setRotateIcon] = useState(false)
    const [openQuestions, setOpenQuestions] = useState([]);
    const [openEyes, setOpenEyes] = useState(false)
    const [loading, setLoading] = useState(false)
    const [nameFrench, setNameFrench] = useState("")
    const [nameEnglish, setNameEnglish] = useState("")
    const [descriptionFrench, setDescriptionFrench] = useState("")
    const [idFaqs, setIdFaqs] = useState("")
    const [questions, setQuestions] = useState([])
    const [descriptionEnglish, setDescriptionEnglish] = useState("")
    const [lang, setLang] = useState(localStorage.getItem("language"))
    const selectRef = useRef(null)
    const token = localStorage.getItem("token")
   const  messageRemove = "suppression reussie"
//    const loadLocalStorageData = () => {
//     const data = localStorage.getItem("language");
//     setLang(data)
   
//   };
  
  useEffect(() => {
    if(localStorage.getItem("language")){
        setLang(localStorage.getItem("language"))
    }
    
  }, [localStorage.getItem("language")]);
   
    const masque = () => {
        return setEtat(true)
    }
    const openEyesFaqs= (id)=>{
        setOpenEyes(true)
        setIdFaqs(id)
        setEtat(true)
        fetchData(
            `faqs/${id}`,
            "GET",
            null,
            token
          ).then((result)=>{
            console.log(result?.result?.data)
            setNameFrench(result?.result?.data.question.fr)
            setDescriptionFrench(result?.result?.data.answer.fr)
            setNameEnglish(result?.result?.data.question.en)
            setDescriptionEnglish(result?.result?.data.answer.en)
          })
    }
    const closePopup = () => {
         setEtat(false)
         setOpenEyes(false)
    }
    function fetchDataFaqs(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                if(lang === "fr" ){
                    const filteredData = result?.result?.data.filter(
                        (item) => item.question?.fr && item.answer?.fr 
                      )
                      .map((item) => ({
                        id: item.id,
                        question: item.question.fr,
                        answer: item.answer.fr,
                      }));
                      setQuestions(filteredData)
                  console.log(filteredData)
                    
                }else{
                    const filteredData = result?.result?.data.filter(
                        (item) => item.question?.en && item.answer?.en 
                      )
                      .map((item) => ({
                        id: item.id,
                        question: item.question.en,
                        answer: item.answer.en,
                      }));
                      setQuestions(filteredData)
                  console.log(filteredData)

                }
                
                
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const url = "faqs/all"
        fetchDataFaqs(url)
    }, [])


    const handleChangeSelect = (id) => {
        setRotateIcon(!rotateIcon);
        if (openQuestions.includes(id) && rotateIcon) {
            setOptionVisible(false);
            // Si la question est déjà ouverte, on la ferme en la retirant de l'état
            setOpenQuestions(openQuestions.filter(questionId => questionId !== id));
        } else {
            setOptionVisible(true)
            // Sinon, on l'ajoute à la liste des questions ouvertes
            setOpenQuestions([...openQuestions, id]);
        }
    };
    
    const handleSubmit = async(e) =>{
        e.preventDefault();
        console.log(true)
        const fields = [
            { name: 'Nom de la question', value: nameFrench },
            { name: 'Reponse de la question', value: descriptionFrench },
            { name: 'nom de la question', value: nameEnglish },
            { name: 'Reponse de la question', value: descriptionEnglish }
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);
            }
        }
        const data = {
            question: {
                en:nameEnglish,
                fr:nameFrench
            },
            answer:{
                en:descriptionEnglish,
                fr:descriptionFrench
            }
        }
        console.log(data)
        try {

            setLoading(true);
            const result = await fetchData("faqs", "POST", data, token)
            if (result.status === 201) {
                snackbbar(document.querySelector("#body"), circleNike, "question creée avec succès", 2000);
                 setTimeout(() => {
                    fetchData(
                        "faqs/all",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        console.log(response);
                        console.log(response?.result?.data)
                        if(lang === "fr" ){
                            const filteredData = result?.result?.data.filter(
                                (item) => item.question?.fr && item.answer?.fr 
                              )
                              .map((item) => ({
                                id: item.id,
                                question: item.question.fr,
                                answer: item.answer.fr,
                              }));
                              setQuestions(filteredData)
                          console.log(filteredData)
                            
                        }else{
                            const filteredData = result?.result?.data.filter(
                                (item) => item.question?.en && item.answer?.en 
                              )
                              .map((item) => ({
                                id: item.id,
                                question: item.question.en,
                                answer: item.answer.en,
                              }));
                              setQuestions(filteredData)
                          console.log(filteredData)
        
                        }
                                         setNameEnglish("")
                                         setNameFrench("")
                                         setDescriptionFrench("")
                                         setDescriptionEnglish("")
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }

    }
    const handlePut = async(e) =>{
        e.preventDefault();
        console.log(true)
        const fields = [
            { name: 'Nom de la question', value: nameFrench },
            { name: 'Reponse de la question', value: descriptionFrench },
            { name: 'nom de la question', value: nameEnglish },
            { name: 'Reponse de la question', value: descriptionEnglish }
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);
            }
        }
        const data = {
            question: {
                en:nameEnglish,
                fr:nameFrench
            },
            answer:{
                en:descriptionEnglish,
                fr:descriptionFrench
            }
        }
        console.log(data)
        try {

            setLoading(true);
            const result = await fetchData(`faqs/${idFaqs}`, "PUT", data, token)
            if (result.status === 200) {
                 snackbbar(document.querySelector("#body"), circleNike, "modification reussie", 2000);
                 setTimeout(() => {
                    fetchData(
                        "faqs/all",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        console.log(response);
                        if(lang === "fr" ){
                            const filteredData = result?.result?.data.filter(
                                (item) => item.question?.fr && item.answer?.fr 
                              )
                              .map((item) => ({
                                id: item.id,
                                question: item.question.fr,
                                answer: item.answer.fr,
                              }));
                              setQuestions(filteredData)
                          console.log(filteredData)
                            
                        }else{
                            const filteredData = result?.result?.data.filter(
                                (item) => item.question?.en && item.answer?.en 
                              )
                              .map((item) => ({
                                id: item.id,
                                question: item.question.en,
                                answer: item.answer.en,
                              }));
                              setQuestions(filteredData)
                          console.log(filteredData)
        
                        }
                                        setNameEnglish("")
                                         setNameFrench("")
                                         setDescriptionFrench("")
                                         setDescriptionEnglish("")
                                          
                        
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }

    }
    const removeFaqs = (userId) => {
        fetchData(`faqs/${userId}`, "Delete", null, token).then((response) => {
          console.log(response)
          if (response.status === 204) {
            snackbbar(document.querySelector("#body"), circleNike, messageRemove, 2000);
            setTimeout(() => {
                fetchData(
                    "faqs/all",
                    "GET",
                    null,
                    token
                  ).then((response) => {
                    console.log(response);
                    const filteredData =response?.result?.data.filter(
                        (item) => item.question?.fr && item.answer?.fr 
                      ).map((item) => ({
                        id: item.id,
                        question: item.question.fr,
                        answer: item.answer.fr,
                      }));
                      setQuestions(filteredData)
                  });
                
            }, 2000);
    
          }
    
        }).catch((error) => {
          console.log(error)
        })
      }
    return (
        <div className="main_accueil">
            {etat && <div className="popup_wrapper">
                <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                    {etat && openEyes ? (
                        <HeaderForm
                             text={`Faq ${idFaqs}`}
                            closePopup={closePopup}
                        />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouvelle question"
                                closePopup={closePopup}
                            />
                        )
                    )}
                    <div className="update_faq">
                        <span className="fr">Français</span>
                        <div className="sous_update_faq" >
                            <div className="children_form">
                                <label htmlFor="">Enoncé de la question</label>
                                <input type="text" value={nameFrench} onChange={(e) =>setNameFrench(e.target.value)}/>
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Réponse à la question</label>
                                <textarea type="text" className="input_faq" value={descriptionFrench} onChange={(e) =>setDescriptionFrench(e.target.value)}/>
                            </div>
                        </div>
                        <span className="fr">Anglais</span>
                        <div className="sous_update_faq">
                            <div className="children_form">
                                <label htmlFor="">Enoncé de la question</label>
                                <input type="text" value={nameEnglish} onChange={(e) =>setNameEnglish(e.target.value)}/>
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Réponse à la question</label>
                                <textarea type="text" className="input_faq" value={descriptionEnglish} onChange={(e) =>setDescriptionEnglish(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="parent_button_tribunal">
                        <span></span>
                        {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal"  onClick={handlePut}>Modifier la question</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter La question</button>
                                )}
                            </>
                        )}
                    </div>
                </form>
            </div>}
            <MainHeaderFaqs text="Centre d'aide" />
            <UpdateTribunal masque={masque} text="+ Ajouter une nouvelle question" />
            <div className="all_parent_question">
                {questions.map((item) => (
                    <div key={item.id}>
                        <div className="parent_question" onClick={() => handleChangeSelect(item.id)}>
                            <div className="check_question">
                                <img src={question} alt="question" />
                                <span className="check_question_span">{item.question}</span>
                            </div>
                            <img
                                src={right}
                                alt="right"
                                className="img_right"
                                style={{
                                    transform: openQuestions.includes(item.id) ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease'
                                }}
                            />
                        </div>
                        {openQuestions.includes(item.id) && (
                            <div className="parent_question_description">
                                <span className="parent_question_description_span">
                                    {item.answer}
                                </span>

                                <div className="seed_remove">
                                    <img src={eyes} alt="" onClick={()=>openEyesFaqs(item.id)}/>
                                    <img src={remover} alt="" onClick={()=>removeFaqs(item.id)}/>
                                </div>
                            </div>
                        )}
                    </div>
                ))}

            </div>


        </div>
    )
}