

// import { Connect } from "./components/componentNotRetulables/connect/Connect";
// import { Header } from "./components/componentNotRetulables/header/Header";
// import { MainAccueil } from "./components/componentNotRetulables/mainAccueil/MainAccueil";
// import { MainAdmin } from "./components/componentNotRetulables/mainAdmin/MainAdmin";
// import { MainCategories } from "./components/componentNotRetulables/mainCategories/MainCategories";
// import { MainCountries } from "./components/componentNotRetulables/mainCountries/MainCountries";
// import { MainDocuments } from "./components/componentNotRetulables/mainDocuments/MainDocuments";
// import { MainFaqs } from "./components/componentNotRetulables/mainFaqs/MainFaqs";
// import { MainJuridictions } from "./components/componentNotRetulables/mainJuridictions/MainJuridictions";
// import { MainTablesJuridictions } from "./components/componentNotRetulables/mainTablesJuridictions/MainTablesJuridictions";
// import { MainTribunaux } from "./components/componentNotRetulables/mainTribunaux/MainTribunaux";
// // import { DetailsDocuments } from "./components/componentNotRetulables/detailsDocuments/DetailsDocuments";
// import { MainTypes } from "./components/componentNotRetulables/mainTypes/MainTypes";
// import { MainVille } from "./components/componentNotRetulables/mainVille/MainVille";
// import { SideBar } from "./components/componentNotRetulables/sideBar/SideBar";
// import { NotificationsProvider } from "./components/repeatableComponents/atomes/NotificationsProvider";
// import "./index.css";
// // import {ErrorBundle} from "./ErrorBundle"
// import { BrowserRouter, Routes, Route } from "react-router-dom";



// function App(){
//   return (
//     <BrowserRouter>
//     {/* <NotificationsProvider> */}
//       <div className="App" id="body">
//         <SideBar />
//         <Header />
//         <div className="main">
//           <Routes>
//             <Route path="/" element={<Connect />}/>
//             <Route path="/home" element={<MainAccueil />}/>
//             <Route path="/tribunaux" element={<MainTribunaux/>}/>
//             <Route path="/types" element={<MainTypes/>} />
//             <Route path="/juridictions" element={<MainJuridictions/>}/>
//             <Route path="/categories" element={<MainCategories/>}/>
//             <Route path="/document" element={<MainDocuments/>}/>
//             <Route path="/admin" element={<MainAdmin/>}/>
//             <Route path="/actor" element={<MainTablesJuridictions/>}/>
//             <Route path="/countries" element={<MainCountries/>}/>
//             <Route path="/city" element={<MainVille/>}/>
//             <Route path="/faqs" element={<MainFaqs/>}/>
//               {/* <Route path="/detail/:id" element={<DetailsDocuments/>} /> */}
//           </Routes>
//         </div>
//       </div>
//       {/* </NotificationsProvider> */}
//     </BrowserRouter>
//   );
// }

// export default App;
import { Connect } from "./components/componentNotRetulables/connect/Connect";
import { Header } from "./components/componentNotRetulables/header/Header";
import { MainAccueil } from "./components/componentNotRetulables/mainAccueil/MainAccueil";
import { MainAdmin } from "./components/componentNotRetulables/mainAdmin/MainAdmin";
import { MainCategories } from "./components/componentNotRetulables/mainCategories/MainCategories";
import { MainCountries } from "./components/componentNotRetulables/mainCountries/MainCountries";
import { MainDocuments } from "./components/componentNotRetulables/mainDocuments/MainDocuments";
import { MainFaqs } from "./components/componentNotRetulables/mainFaqs/MainFaqs";
import { MainJuridictions } from "./components/componentNotRetulables/mainJuridictions/MainJuridictions";
import { MainTablesJuridictions } from "./components/componentNotRetulables/mainTablesJuridictions/MainTablesJuridictions";
import { MainTribunaux } from "./components/componentNotRetulables/mainTribunaux/MainTribunaux";
import { MainTypes } from "./components/componentNotRetulables/mainTypes/MainTypes";
import { MainVille } from "./components/componentNotRetulables/mainVille/MainVille";
import { SideBar } from "./components/componentNotRetulables/sideBar/SideBar";
import { NotificationsProvider } from "./components/repeatableComponents/atomes/NotificationsProvider";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <NotificationsProvider> {/* Envelopper avec NotificationsProvider */}
        <div className="App" id="body">
          <SideBar />
          <Header />
          <div className="main">
            <Routes>
              <Route path="/" element={<Connect />} />
              <Route path="/home" element={<MainAccueil />} />
              <Route path="/tribunaux" element={<MainTribunaux />} />
              <Route path="/types" element={<MainTypes />} />
              <Route path="/juridictions" element={<MainJuridictions />} />
              <Route path="/categories" element={<MainCategories />} />
              <Route path="/document" element={<MainDocuments />} />
              <Route path="/admin" element={<MainAdmin />} />
              <Route path="/actor" element={<MainTablesJuridictions />} />
              <Route path="/countries" element={<MainCountries />} />
              <Route path="/city" element={<MainVille />} />
              <Route path="/faqs" element={<MainFaqs />} />
            </Routes>
          </div>
        </div>
      </NotificationsProvider> {/* Fin de l'enveloppe */}
    </BrowserRouter>
  );
}

export default App;
