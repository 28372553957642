import search from "../../../../assets/icons/search.png"
import "./statRectangle.css"
import afrique from "../../../../assets/icons/afrique.png"
import iconegraphe from "../../../../assets/icons/iconegraphe.png"
export const StatRectangle = ({ text, number, pourcent, date }) => {
    return (
        <div className='parent_stat'>
            <div className="parent_stat_1">
                <div className="parent_text">
                    <span className="parent_text_1">{text}</span>
                    <span className="parent_text_2">{number}</span>
                </div>
                <img src={afrique} alt="" className="stat_img"/>
            </div>
            <div className="parent_stat_2">
                <img src={iconegraphe} alt="" className="iconegraphe"/>
                <div className="parent_created">
                <span className="stat_pourcent">+{pourcent}</span>
                <span className="date">{date}</span>
                </div>
                {/* <span className="date"><span className="stat_pourcent">+{pourcent}</span> {date}</span> */}
            </div>
        </div>
    )
}